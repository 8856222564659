// images.js
import slot1 from '../images/slot/1.png'
import slot2 from '../images/slot/2.png'
// import slot3 from '../images/slot/3.png'
import slot4 from '../images/slot/4.png'
// import slot5 from '../images/slot/5.png'
// import slot6 from '../images/slot/6.png'
// import slot7 from '../images/slot/1.png'
// import slot8 from '../images/slot/1.png'
// import slot9 from '../images/slot/1.png'
// Remplacez ces chemins par les chemins réels vers vos images de machine à sous
const images = [
      slot1,
      slot2,
      
      slot4,
    
    
];
  
  export default images;
