import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Form from './Pages/form';
import Acceuil from './Pages/accueil';
import Slot from './Pages/slot';
import Win from './Pages/win';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>

          <Route path="/form" element={<Form />} />
          <Route path="/" element={<Acceuil />} />
          <Route path="/jackpot" element={<Slot />} />
          <Route path="/win" element={<Win />} />
          
          
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
