import React from "react";
import { Button } from "@nextui-org/react";
import {  doc, getDoc, setDoc } from "firebase/firestore";
import db from '../firebase'; // Assurez-vous que db est correctement configuré pour Firestore
import "firebase/firestore";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function Question() {
  const navigate = useNavigate();
  const auth = getAuth();

// Utilisez onAuthStateChanged pour écouter les changements d'état de l'utilisateur
onAuthStateChanged(auth, (user) => {
  if (user) {
    // L'utilisateur est connecté, vous pouvez accéder à son ID
    const userID = user.uid;
    // Maintenant, vous pouvez utiliser userID dans votre handleSubmit
    handleSubmit(userID);
  } else {
    // L'utilisateur n'est pas connecté
  }
});


  



const handleSubmit = async () => {
    // Assurez-vous que l'utilisateur est authentifié de manière anonyme
    const user = auth.currentUser;
    if (!user) {
      try {
        await signInAnonymously(auth);
      } catch (error) {
        console.error("Erreur lors de l'authentification anonyme : ", error);
        return;
      }
    }

    // Récupérez l'ID utilisateur anonyme
    const userID = user.uid;

    // Enregistrez les réponses dans la collection "Participants" avec l'ID utilisateur anonyme
    const participantDocRef = doc(db, "Participants", userID);

    try {
      // Vérifiez d'abord si le document existe
      const participantDocSnap = await getDoc(participantDocRef);

      if (participantDocSnap.exists()) {
        // Si le document du participant existe, mettez à jour les réponses
        await setDoc(participantDocRef, { gagnant: true }, { merge: true });
      } else {
        // Si le document du participant n'existe pas, créez-le avec les réponses
        await setDoc(participantDocRef, { gagnant: true });
      }
      
      // onOpenChange();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement des réponses : ", error);
    }
    navigate('/win');
  };
  
  

  return (
    <>
    
      
      
          <Button
            style={{ backgroundColor: "#133eeb",  color: "white" }}
            onPress={() => {
              handleSubmit();
              
            }}
          >
            Soumettre
          </Button>
          


    </>
  );
}
