import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCuljgsTCukpSo0h68rioBLYSk7GVpOxGE",
  authDomain: "jackpot-8cad0.firebaseapp.com",
  projectId: "jackpot-8cad0",
  storageBucket: "jackpot-8cad0.appspot.com",
  messagingSenderId: "933901131142",
  appId: "1:933901131142:web:57c2f0dfaa3e3d551fa007",
  measurementId: "G-5S4YYQG2FT"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;