
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';

// La durée de l'animation pour un cycle complet de défilement
const spinDuration = 2;

// La hauteur de chaque image dans le spinner
const imageHeight = 100; // ajustez selon la taille de vos images

const Spinner = ({ images, spinning, index, stoppedPosition }) => {

  

  // Créez une liste d'images qui contient plusieurs copies pour un défilement continu
  const imageList = [...Array(10)].flatMap(() => images).map((img, index) => (
    <img
      key={index}
      src={img}
      alt={`Slot Image ${index}`}
      style={{ width: '100%', height: `${imageHeight}px` }} // la hauteur doit correspondre à imageHeight
    />
  ));

  // Générer une valeur d'arrêt aléatoire pour chaque spinner
  const stopPosition = -imageHeight * Math.floor(Math.random() * images.length);

  const controls = useAnimation();

  useEffect(() => {
    if (spinning) {
      // Démarrer l'animation avec un léger délai basé sur l'index pour désynchroniser les spinners
      const delay = index * 0.1; // Un léger délai pour chaque spinner basé sur son index
      controls.start({
        translateY: [-imageHeight * 10, 0],
        transition: {
          loop: Infinity,
          duration: spinDuration + delay, // Varier la durée légèrement pour chaque spinner
          ease: 'linear',
          delay: delay
        }
      });
    }  else {
      // Arrêter à la position spécifiée
      controls.start({
        translateY: stoppedPosition,
        transition: { duration: 0.2, ease: 'linear' }
      });
    }
  }, [spinning, stoppedPosition, controls]);

  // useEffect(() => {
  //   if (!spinning) {
  //     // Arrêter le spinner à une position aléatoire
  //     controls.set({ translateY: stopPosition });
  //   }
  // }, [spinning, stopPosition, controls]);

  return (
    <div className="spinner" style={{ overflow: 'hidden', height: `${imageHeight}px` }}>
      <motion.div
        animate={controls}
        initial={false}
        variants={{
          spinning: {
            translateY: [-imageHeight * 10, 0],
            transition: {
              loop: Infinity,
              duration: spinDuration,
              ease: 'linear'
            }
          },
          stopped: {
            translateY: stopPosition,
            transition: {
              duration: 0,
            }
          }
         
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        
      >
        {imageList}
      </motion.div>
    </div>
  );
};

Spinner.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  spinning: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  stoppedPosition: PropTypes.number.isRequired, // Ajout de la prop de position d'arrêt
};

export default Spinner;
